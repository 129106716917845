import React from "react";
import GATSBY_COMPILED_MDX from "/home/naman/Desktop/rustp/website/pages/docs/5 Algorithms/5.2 Dynamic Programming/Longest-Common-Subsequence.mdx";
import {graphql} from 'gatsby';
export const pageQuery = graphql`
  query DocPageQuery($id: String!) {
    mdx(id: { eq: $id }) {
      fields {
        pageType
        title
        editLink
      }
      tableOfContents
    }
  }
`;
function DocTemplate({children}) {
  return children;
}
DocTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(DocTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
